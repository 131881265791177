import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentSnackBar.figmaUrl.ios} codeUrl={checklists.componentSnackBar.codeUrl.ios} checklists={checklists.componentSnackBar.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`Snackbars are UI elements that notify users about background processes or events within the app. They typically appear temporarily on the screen, with customizable duration and position (top, center, or bottom), ensuring they don’t interrupt the user experience. Snackbars do not require user interaction to dismiss and are commonly used to display brief messages, such as tooltips or popups, at the bottom of the screen.`}</p>
    <h2>{`Usage`}</h2>
    <p>{`To use the Legion iOS UIKit theme, you need to import one of the available themes.
Currently, the following themes are supported: ThemeAGR, ThemeEazy, ThemeIHS, ThemeLGN, and ThemeMyTEnS.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import ThemeLGN
`}</code></pre>
    <div className="divi" />
    <h2>{`Sizes`}</h2>
    <p>{`Legion offers two sizes of SnackBar:`}</p>
    <h3>{`Medium`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-uikit/ios-uikit-snackbar-medium.png",
      "alt": "LGNSnackBarUIKit Medium Size"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let snackBar = LGNSnackBarUIKit(
    size: .medium, // Optional (default)
    description: "Description"
)
`}</code></pre>
    <h3>{`Small`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-uikit/ios-uikit-snackbar-small.png",
      "alt": "LGNSnackBarUIKit Small Size"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let snackBar = LGNSnackBarUIKit(
    size: .small,
    description: "Description"
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Variant`}</h2>
    <h3>{`Default`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-uikit/ios-uikit-snackbar-variant-default.png",
      "alt": "LGNSnackBarUIKit Variant Default"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let snackBar = LGNSnackBarUIKit(
    variant: .default,
    title: "Title",
    description: "Description",
    leftIcon: UIImage(systemName: "exclamationmark.circle.fill"),
    avatar: (.imageURL(URL(string: "https://cdn-icons-png.flaticon.com/512/1946/1946431.png")!)),
    withDivider: true,
    actionButtonTitle: "Submit"
)
`}</code></pre>
    <h3>{`Tertiary`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-uikit/ios-uikit-snackbar-variant-tertiary.png",
      "alt": "LGNSnackBarUIKit Variant Tertiary"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let snackBar = LGNSnackBarUIKit(
    variant: .tertiary,
    title: "Title",
    description: "Description",
    leftIcon: UIImage(systemName: "exclamationmark.circle.fill"),
    avatar: (.imageURL(URL(string: "https://cdn-icons-png.flaticon.com/512/1946/1946431.png")!)),
    withDivider: true,
    actionButtonTitle: "Submit"
)
`}</code></pre>
    <h3>{`Warning`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-uikit/ios-uikit-snackbar-variant-warning.png",
      "alt": "LGNSnackBarUIKit Variant Warning"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let snackBar = LGNSnackBarUIKit(
    variant: .warning,
    title: "Title",
    description: "Description",
    leftIcon: UIImage(systemName: "exclamationmark.circle.fill"),
    avatar: (.imageURL(URL(string: "https://cdn-icons-png.flaticon.com/512/1946/1946431.png")!)),
    withDivider: true,
    actionButtonTitle: "Submit"
)
`}</code></pre>
    <h3>{`Error`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-uikit/ios-uikit-snackbar-variant-error.png",
      "alt": "LGNSnackBarUIKit Variant Error"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let snackBar = LGNSnackBarUIKit(
    variant: .error,
    title: "Title",
    description: "Description",
    leftIcon: UIImage(systemName: "exclamationmark.circle.fill"),
    avatar: (.imageURL(URL(string: "https://cdn-icons-png.flaticon.com/512/1946/1946431.png")!)),
    withDivider: true,
    actionButtonTitle: "Submit"
)
`}</code></pre>
    <h3>{`Information`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-uikit/ios-uikit-snackbar-variant-info.png",
      "alt": "LGNSnackBarUIKit Variant Information"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let snackBar = LGNSnackBarUIKit(
    variant: .information,
    title: "Title",
    description: "Description",
    leftIcon: UIImage(systemName: "exclamationmark.circle.fill"),
    avatar: (.imageURL(URL(string: "https://cdn-icons-png.flaticon.com/512/1946/1946431.png")!)),
    withDivider: true,
    actionButtonTitle: "Submit"
)
`}</code></pre>
    <h3>{`Success`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-uikit/ios-uikit-snackbar-variant-success.png",
      "alt": "LGNSnackBarUIKit Variant Success"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let snackBar = LGNSnackBarUIKit(
    variant: .success,
    title: "Title",
    description: "Description",
    leftIcon: UIImage(systemName: "exclamationmark.circle.fill"),
    avatar: (.imageURL(URL(string: "https://cdn-icons-png.flaticon.com/512/1946/1946431.png")!)),
    withDivider: true,
    actionButtonTitle: "Submit"
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Title`}</h2>
    <p>{`You can optionally add a title to the SnackBar.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-uikit/ios-uikit-snackbar-title.png",
      "alt": "LGNSnackBarUIKit with Title"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let snackBar = LGNSnackBarUIKit(
    title: "Title",
    description: "Description",
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Description (Required)`}</h2>
    <p>{`The description is a required field and displays the main message in the SnackBar.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-uikit/ios-uikit-snackbar-medium.png",
      "alt": "LGNSnackBarUIKit with Description"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let snackBar = LGNSnackBarUIKit(
    description: "Description"
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Left Icon`}</h2>
    <p>{`Optionally add a left icon to the SnackBar for visual context.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-uikit/ios-uikit-snackbar-left-icon.png",
      "alt": "LGNSnackBarUIKit with Left Icon"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let snackBar = LGNSnackBarUIKit(
    description: "Description",
    leftIcon: UIImage(systemName: "exclamationmark.circle.fill")
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Avatar`}</h2>
    <p>{`You can display an avatar in the SnackBar using an image URL, a UIImage, or string initials.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-uikit/ios-uikit-snackbar-avatar.png",
      "alt": "LGNSnackBarUIKit with Avatar"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let avatarURL = .imageURL(URL(string: "https://cdn-icons-png.flaticon.com/512/1946/1946431.png")!)
let avatarUIImage = (.image(UIImage(systemName: "xmark") ?? UIImage()))
let avatarInitials = (.initials("Legion Design System"))

let snackBar = LGNSnackBarUIKit(
    description: "Description",
    avatar: avatarInitials
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Divider`}</h2>
    <p>{`Optionally add a divider between the left icon/avatar/title/description and the action button/close button.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-uikit/ios-uikit-snackbar-divider.png",
      "alt": "LGNSnackBarUIKit with Divider"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let snackBar = LGNSnackBarUIKit(
    description: "Description",
    withDivider: true
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Action Button`}</h2>
    <p>{`Add an optional action button with a title.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-uikit/ios-uikit-snackbar-action-button.png",
      "alt": "LGNSnackBarUIKit with Action Button"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let snackBar = LGNSnackBarUIKit(
    description: "Description",
    actionButtonTitle: "Submit"
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Close Button (Default is True)`}</h2>
    <p>{`You can choose whether to include a close button (dismiss control). By default, this is set to true.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-uikit/ios-uikit-snackbar-medium.png",
      "alt": "LGNSnackBarUIKit without Close Button"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let snackBar = LGNSnackBarUIKit(
    description: "Description",
    withCloseButton: false
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Full Configuration Example`}</h2>
    <p>{`Here’s an example of a fully-configured SnackBar:`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/ios-uikit/ios-uikit-snackbar-full-configuration.png",
      "alt": "LGNSnackBarUIKit Full Configuration"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let snackBar = LGNSnackBarUIKit(
    size: .medium,
    title: "Title",
    description: "Description",
    leftIcon: UIImage(systemName: "exclamationmark.circle.fill"),
    avatar: (.initial(initial: "Legion Design System")),
    withDivider: true,
    actionButtonTitle: "Submit",
    withCloseButton: true,
    onTapAction: {
        print("Action button tapped")
    },
    onDismissed: {
        print("SnackBar dismissed")
    }
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Tracking the Action or Dismissal`}</h2>
    <p>{`You can track the action button tap and the dismissal of the SnackBar.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let snackBar = LGNSnackBarUIKit(
    description: "Description",
    onTapAction: {
        print("Action button tapped")
    },
    onDismissed: {
        print("SnackBar dismissed")
    }
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Custom`}</h2>
    <p>{`Customize the position, padding, and duration when displaying the SnackBar.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let snackBar = LGNSnackBarUIKit(
    description: "Description"
)

snackBar.show(
    in: self.view,
    position: .bottom, // Set the position (top, center, bottom)
    leadingPadding: 21, // Set padding from the left
    trailingPadding: 21, // Set padding from the right
    duration: 5.0 // Set duration before dismissing
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Properties`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actionButtonTitle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The title of the action button that appears in the SnackBar.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`avatar`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The avatar type to display in the SnackBar. Can be a URL, UIImage, or initials.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`description`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The main content to display in the SnackBar. This is a required field.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(Required)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration for which the SnackBar will be shown, in seconds. Set to `}<inlineCode parentName="td">{`nil`}</inlineCode>{` for an indefinite duration.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`3.0`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`leftIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An optional image to display on the left side of the SnackBar.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onTapAction`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Closure to execute when the action button is tapped.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onDismissed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Closure to execute when the SnackBar is dismissed (either automatically or manually).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`position`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The position of the SnackBar on the screen. Options include `}<inlineCode parentName="td">{`.top`}</inlineCode>{`, `}<inlineCode parentName="td">{`.center`}</inlineCode>{`, or `}<inlineCode parentName="td">{`.bottom`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bottom`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`show(in:position:padding:duration:)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A method that displays the SnackBar in the specified parent view with custom position, padding, and duration.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(Method)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The size of the SnackBar. Choose between `}<inlineCode parentName="td">{`.medium`}</inlineCode>{` or `}<inlineCode parentName="td">{`.small`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.medium`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The title text to display in the SnackBar (optional).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`withCloseButton`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Determines whether the close button (dismiss control) is shown.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`withDivider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Determines whether to display a divider between the content (icon/avatar/title/description) and the buttons.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      